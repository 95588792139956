<template>
          <apexchart
            type="bubble"
            :options="chartOptions"
            :series="series"
            ref="quizbubbleChart"
            height="425"
            class="ms-n5 min-h-auto" style="height: 425px; min-height: 440px;"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
    props:{
        seriesData:{
            type:Object
        }
    },
    components: {
        apexchart: VueApexCharts,
    },
    watch:{
        seriesData(){        
            this.updateChart(this.seriesData.qualified,this.seriesData.general,this.seriesData.disqualified)
        }
    },
    data(){
        return{
             series: [{
                        name: "Hot",
                        data: [[100,250,0]]
                    }, {
                        name: "Qualified",
                       data:[[150,300,0] ]
                    }, {
                        name: "General",
                        data: [[170, 400,0]]
                    }, {
                        name: "Disqual",
                        data:[[200,250,0]]
                    }],
            chartOptions:{
               chart: {
                        fontFamily: "inherit",
                        type: "bubble",
                        height: 425,
                        toolbar: {
                            show: !1
                        }
                    },
                    plotOptions: {
                        bubble: {}
                    },
                    // stroke: {
                    //     show: !1,
                    //     width: 0
                    // },
                    legend: {
                        show: 1
                    },
                    dataLabels: {
                        enabled: !1
                    },
                    xaxis: {
                        type: "numeric",
                        tickAmount: 7,
                        min: 0,
                        max: 700,
                        axisBorder: {
                            show: !1
                        },
                        axisTicks: {
                            show: !0,
                            height: 0
                        },
                        labels: {
                            show: !0,
                            trim: !0,
                            style: {
                                colors: '#A1A5B7',
                                fontSize: "13px"
                            }
                        }
                    },
                    yaxis: {
                        tickAmount: 7,
                        min: 0,
                        max: 700,
                        labels: {
                            style: {
                                colors: '#A1A5B7',
                                fontSize: "13px"
                            }
                        }
                    },
                    tooltip: {
                        enabled:true,
                        style: {
                            fontSize: "12px"
                        }
                    },
                    crosshairs: {
                        show: !0,
                        position: "front",
                        stroke: {
                            color: '#E4E6EF',
                            width: 1,
                            dashArray: 0
                        }
                    },
                    colors: ['#e49c35', '#0B1636', '#FFC700', '#F1416C'],
                    fill: {
                        opacity: 1
                    },
                    grid: {
                        borderColor: '#E4E6EF',
                        strokeDashArray: 4,
                        padding: {
                            right: 20
                        },
                        yaxis: {
                            lines: {
                                show: !0
                            }
                        }
                    } 
            }

        }
    },
    methods:{
        updateChart(q,g,d){
            this.$refs.quizbubbleChart.updateOptions({
                series: [{
                        name: "Hot",
                        data: [[100,250,0]]
                    }, {
                        name: "Qualified",
                       data:[[150,300,q] ]
                    }, {
                        name: "General",
                        data: [[170, 400,g]]
                    }, {
                        name: "Disqual",
                        data:[[200,250,d]]
                    }],
            });

        }
    },
    mounted(){
        console.log(this.seriesData)
    //     setTimeout(() => {
    //             this.updateChart(this.seriesData.qualified,this.seriesData.general,this.seriesData.disqualified)
    //   }, 2000);
    }
}
</script>