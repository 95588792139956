<template>
  <div id="map_chart" class="w-100 h-425px"></div>
</template>
<script>
 /* eslint-disable */ 
import { defineComponent } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
export default defineComponent({
  props:{
    seriesData:{
      type:Array
    }
  },
  watch:{
    seriesData(){
      this.seriesData.forEach((value) => {
        this.oldPointSeries.data.push({
          geometry: { type: "Point", coordinates: [value.longitude,value.latitude] },
          title:value.title,
        });
      });
    
    }
  },
  data() {
    return {
      map: "",
      pointSeries: "",
      oldPointSeries: "",
      newPointSeries: "",
    };
  },
  mounted() {
    let newPointSeries = "";
    let oldPointSeries = "";
    var root = am5.Root.new("map_chart");
    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "translateY",
        projection: am5map.geoMercator(),
      })
    );

    var cont = chart.children.push(
      am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: 20,
        y: 40,
      })
    );
    var zoomControl = chart.set(
      "zoomControl",
      am5map.ZoomControl.new(root, {})
    );
    var backgroundSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {})
    );
    backgroundSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      strock: "#181C32",
      fillOpacity: 0,
      strokeOpacity: 0,
    });

    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180),
    });

    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        fill: "#D2D6DE",
      })
    );

    var lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
    lineSeries.mapLines.template.setAll({
      stroke: root.interfaceColors.get("alternativeBackground"),
      strokeOpacity: 0.3,
    });

    var pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
    var colorset = am5.ColorSet.new(root, {});
    pointSeries.bullets.push(function () {
      var container = am5.Container.new(root, {});

      var circle = container.children.push(
        am5.Circle.new(root, {
          radius: 0,
          tooltipY: 0,
          fill: "#BBBBBB",
          strokeOpacity: 0,
          tooltipText: "{title}",
        })
      );

      var circle2 = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: "#BBBBBB",
          strokeOpacity: 0,
          tooltipText: "{title}",
        })
      );

      circle.animate({
        key: "scale",
        from: 1,
        to: 5,
        duration: 600,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });
      circle.animate({
        key: "opacity",
        from: 1,
        to: 0,
        duration: 600,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });

      return am5.Bullet.new(root, {
        sprite: container,
      });
    });
    var cities = [];

    for (var i = 0; i < cities.length; i++) {
      var city = cities[i];
      addCity(city.longitude, city.latitude, city.title);
    }

    function addCity(longitude, latitude, title) {
      pointSeries.data.push({
        geometry: { type: "Point", coordinates: [longitude, latitude] },
        title: title,
      });
    }
    oldPointSeries = pointSeries;

    newPointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
    //  var colorset = am5.ColorSet.new(root, {});

    newPointSeries.bullets.push(function () {
      var container = am5.Container.new(root, {});

      var circle = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: "#EBBC42",
          strokeOpacity: 0,
          tooltipText: "{title}",
        })
      );

      var circle2 = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: "#EBBC42",
          strokeOpacity: 0,
          tooltipText: "{title}",
        })
      );

      circle.animate({
        key: "scale",
        from: 1,
        to: 5,
        duration: 600,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });
      circle.animate({
        key: "opacity",
        from: 1,
        to: 0,
        duration: 600,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });

      return am5.Bullet.new(root, {
        sprite: container,
      });
    });
    this.oldPointSeries = pointSeries;
    this.newPointSeries = newPointSeries;
    chart.appear(1000, 100);
    this.map = chart;
    this.pointSeries = pointSeries;
  },
  methods: {
    handleSwitchMap(e) {
      if (!e.target.checked) {
        this.map.set("projection", am5map.geoMercator());
        this.map.set("panY", "translateY");
        this.map.set("rotationY", 0);
      } else {
        this.map.set("projection", am5map.geoOrthographic());
        this.map.set("panY", "rotateY");
      }
    },
    resetData() {
      this.oldPointSeries.data.clear();
      this.newPointSeries.data.clear();
      this.$emit('clearAll')
    },
    addData() {
      this.newPointSeries.data.push({
        geometry: { type: "Point", coordinates: ["32.856", "39.9439"] },
        title: "Ankara",
      });
    },
    addNewData(data=null){
      if(data){
        this.newPointSeries.data.push({
          geometry: { type: "Point", coordinates: [data.longitude, data.latitude] },
          title: data.ip,
        });
      }

    }
  },
});
</script>
