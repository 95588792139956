<template>
  <apexchart
    type="bar"
    ref="topCampainBar"
    :options="chartOptions"
    :series="series"
    class="min-h-auto"
    style="height: 127px"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    top_cart_name: {
      type: Array,
    },
    top_cart_data: {
      type: Array,
    },
  },
  watch: {
    top_cart_data() {
      this.$refs.topCampainBar.updateOptions({
        series: [
          {
            data: this.top_cart_data,
          },
        ],
        xaxis: {
          categories: this.top_cart_name,
        },
      });
    },
  },
  data() {
    return {
      series: [
        {
          data: this.top_cart_data,
          show: !1,
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 170,
          toolbar: {
            show: !1,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: !0,
            distributed: !0,
            barHeight: 23,
          },
        },
        dataLabels: {
          enabled: !1,
        },
        legend: {
          show: !1,
        },
        colors: ["#3E97FF", "#F1416C", "#50CD89", "#FFC700", "#7239EA"],
        xaxis: {
          categories: this.top_cart_name,
          tickAmount: 3,
          labels: {
            formatter: function (e) {
              return e.toFixed(0);
            },
            style: {
              colors: "#B5B5C3",
              fontSize: "12px",
              fontWeight: "600",
              align: "left",
            },
          },
          axisBorder: {
            show: !1,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#3F4254",
              fontSize: "12px",
              fontWeight: "600",
            },
            offsetY: 2,
            align: "left",
          },
        },
        grid: {
          borderColor: "#fff",
          xaxis: {
            lines: {
              show: !0,
            },
          },
          yaxis: {
            lines: {
              show: !1,
            },
          },
          strokeDashArray: 4,
        },
      },
    };
  },
});
</script>
