<template>
  <div
    id="kt_card_widget_4_chart"
    style="min-width: 70px; min-height: 70px"
    data-kt-size="70"
    data-kt-line="11"
  ></div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
    props:{
        seriesData:{
            type:Array
        }
    },
    watch:{
        seriesData(){
            this.updateData()
        }
    },
    data(){
        return{
            s:"",
            t:""
        }
    },
  mounted() {
    var e = document.getElementById("kt_card_widget_4_chart");
    if (e) {
      var t = {
          size: e.getAttribute("data-kt-size")
            ? parseInt(e.getAttribute("data-kt-size"))
            : 70,
          lineWidth: e.getAttribute("data-kt-line")
            ? parseInt(e.getAttribute("data-kt-line"))
            : 11,
          rotate: e.getAttribute("data-kt-rotate")
            ? parseInt(e.getAttribute("data-kt-rotate"))
            : 145,
        },
        a = document.createElement("canvas"),
        r = document.createElement("span");
      "undefined" != typeof window.G_vmlCanvasManager &&
        window.G_vmlCanvasManager.initElement(a);
      var o = a.getContext("2d");
      (a.width = a.height = t.size),
        e.appendChild(r),
        e.appendChild(a),
        o.translate(t.size / 2, t.size / 2),
        o.rotate((t.rotate / 180 - 0.5) * Math.PI);
      var i = (t.size - t.lineWidth) / 2,
        s = function (e, t, a) {
          (a = Math.min(Math.max(0, a || 1), 1)),
            o.beginPath(),
            o.arc(0, 0, i, 0, 2 * Math.PI * a, !1),
            (o.strokeStyle = e),
            (o.lineCap = "round"),
            (o.lineWidth = t),
            o.stroke();
        };
      this.seriesData.forEach((value) => {
        s(value.color, t.lineWidth, value.devided_value);
      });
      this.s=s
      this.t=t
    }
  },
  methods: {
      updateData(){
       this.seriesData.forEach((value) => {
        this.s(value.color, this.t.lineWidth, value.devided_value);
      });   
      }
  },
});
</script>
